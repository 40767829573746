<template>
    <o-data-lookup :data-object="dsDailyLogEntries">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <slot v-else :target="target" />
        </template>
        <o-column field="ID" width="100"></o-column>
        <o-column field="Description" width="200"></o-column>
        <o-column field="EntryDateAndTime" width="150"></o-column>
        <o-column field="CreatedBy" width="150"></o-column>
        <o-column field="OrgUnit" width="200"></o-column>
    </o-data-lookup>
</template>

<script setup>

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,        
});

const dsDailyLogEntries = $getDataObjectById("dsComplete_DailyLogEntries");
</script>
